/*------- 11. About style ------ */

.welcome-content {
  h5 {
    font-weight: 500;
    font-size: 16px;
    color: #666;
    margin: 0;
    letter-spacing: 0.3px;
  }
  h1 {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin: 8px 0 30px;
    color: #433f3f;
    position: relative;
    display: inline-block;
    padding: 0 0 23px;
    line-height: 1;
    &:before {
      position: absolute;
      background-color: #070508;
      height: 3px;
      width: 70px;
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
    }
  }
  p {
    font-size: 16px;
    color: #4b4b4b;
    line-height: 30px;
    width: 66%;
    margin: 0 auto;
    @media #{$lg-layout} {
      width: 80%;
    }
    @media #{$md-layout} {
      width: 95%;
    }
    @media #{$xs-layout} {
      width: 100%;
      font-size: 15px;
    }
  }
}

/* about page */

.single-count {
  .count-icon {
    i {
      font-size: 50px;
      line-height: 1;
      display: inline-block;
      @media #{$md-layout} {
        font-size: 45px;
      }
      @media #{$xs-layout} {
        font-size: 45px;
      }
    }
  }
  h2 {
    & > span {
      color: $theme-color;
      font-size: 40px;
      font-weight: bold;
      margin: 25px 0 12px;
      display: inline-block;
      @media #{$md-layout} {
        margin: 10px 0 8px;
        font-size: 35px;
      }
      @media #{$xs-layout} {
        margin: 6px 0 8px;
        font-size: 35px;
      }
    }
  }
  span {
    color: #333;
    font-size: 20px;
    font-weight: 500;
  }
}

.team-wrapper {
  background-color: #f7f7f7;
  .team-img {
    position: relative;

    img {
      width: 100%;
    }

    .team-action {
      background: #fff none repeat scroll 0 0;
      left: 0;
      opacity: 0;
      padding: 10px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 70%;
      transform: translateY(-50%);
      transition: all 0.6s ease 0s;
      width: 100%;
      z-index: 5;
      a {
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        font-size: 14px;
        height: 30px;
        line-height: 28px;
        text-align: center;
        width: 30px;
        margin: 0 3px;
        &.facebook {
          border: 1px solid #3b5998;
          background-color: #3b5998;
          &:hover {
            background-color: transparent;
            color: #3b5998;
          }
        }
        &.twitter {
          border: 1px solid #55acee;
          background-color: #55acee;
          &:hover {
            background-color: transparent;
            color: #55acee;
          }
        }
        &.instagram {
          border: 1px solid #c32aa3;
          background-color: #c32aa3;
          &:hover {
            background-color: transparent;
            color: #c32aa3;
          }
        }
      }
    }
  }
  .team-content {
    padding: 20px 10px 23px;
    h4 {
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 3px;
      text-transform: capitalize;
    }
    span {
      font-size: 15px;
      font-style: italic;
    }
  }
  &:hover .team-action {
    top: 50%;
    opacity: 1;
  }
}

.single-mission {
  h3 {
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 20px;
    text-transform: capitalize;
    line-height: 22px;
    color: #333;
    @media #{$xs-layout} {
      margin: 0 0 10px;
    }
  }
  p {
    margin: 0;
  }
}

.about-brand-logo {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

@media #{$xs-layout} {
  .bullet-mob {
    flex-direction: row;
    justify-content: start;
  }
}

.main-div-profile {
  @media #{$xs-layout} {
    padding: 20px;
  }
  h3 {
    color: $theme-color;
    @media #{$xs-layout} {
      font-size: 1rem;
    }
  }
  padding-top: 2rem;
  .start-text {
    font-size: 1.5rem;
    line-height: 2rem;
    @media #{$xs-layout} {
      font-size: 1rem;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media #{$xs-layout} {
    text-align: center !important;
    p {
      width: 100% !important;
      font-size: 1rem;
      text-align: start;
      margin-left: 0.2rem;
    }
  }
  text-emphasis: center;
  .title {
    h4 {
      text-align: center;
      font-style: italic;
      color: $theme-color;
    }
  }

  .philosophy-row {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: baseline;

    @media #{$xs-layout} {
      flex-wrap: wrap;
      padding: 1rem;
    }
    div {
      display: flex;
      flex-direction: column;
      width: 30rem;
      text-align: center;
      h3 {
        color: $theme-color;
      }
      @media #{$xs-layout} {
        margin-bottom: 4rem;
      }
    }
  }

  .services-column {
    .Collapsible__trigger {
      color: $theme-color;
      font-family: $montsemibold;
    }
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    .services-box {
      display: flex;
      flex-direction: row;
      margin: 1rem;
      margin-left: 0rem;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 0.5rem;
      padding: 0.5rem 6rem 0px;
      @media #{$xs-layout} {
        flex-wrap: wrap;
        padding-left: 0;
        margin-left: auto;
        padding: 0.5rem 0rem 0px;
        padding-left: 0rem !important;
        align-items: center;
        justify-content: center;
      }

      .service-img {
        background-size: cover;
        width: 20rem;
        height: 20rem;
        border-radius: 100%;
        margin-right: 40px;
        border: 10px solid $secundary-color;
        background-position: center center;
        @media #{$xs-layout} {
          height: 19rem;
          margin: 0;
        }
      }
      .text {
        @media #{$xs-layout} {
          width: 90%;
          margin-top: 2rem;
        }
        h3 {
          text-transform: uppercase;
          color: $theme-color;
          margin-bottom: 1rem;
        }
        width: 68%;
      }
    }
  }
}

.im-so-tired {
  padding-left: 6rem;
  @media #{$xs-layout} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.bg-circle-team {
  background-position: bottom;
  background-size: cover;
  width: 15rem;
  height: 15rem;
  border-radius: 100%;
  border: 10px solid $third-color;
}

.gallery-box {
  h5 {
    text-align: center;
    width: 15rem;
    margin-top: 1rem;
    margin-left: 8px;
  }
  form {
    display: flex;
    flex-direction: column;

    input {
      border: 1px solid $theme-color !important;
      background-color: transparent;
      color: $theme-color;
      border: none;
      padding: 1rem;
      width: 15rem;
      margin: 0.5rem;

      &::placeholder {
        color: $theme-color;
      }
    }
    button {
      width: 10rem;
      background-color: $theme-color;
      color: #fff;
      border: none;
      margin-top: 0.5rem;
      padding: 0.5rem;
    }
  }
  width: 100%;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  img {
    width: 30rem !important;
  }
  #action-buttons {
    background-color: $theme-color;
    color: #fff;
    border: none;
    padding: 10rem;
    padding: 1rem;
    width: 15rem;
    margin: 0.5rem;
    &:hover {
      background-color: $secundary-color;
      color: #fff;
    }
  }
  #lightgallery {
    background-color: $theme-color;
    border: none;
    i {
      color: #fff;
    }
    border-radius: 20px;
  }
  @media #{$xs-layout} {
    width: 100%;
    flex-wrap: wrap;

    h3 {
      width: 100%;
      margin-bottom: 3rem;
      margin-top: 3rem;
    }
  }
  .bg-poster {
    background-size: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0.5rem;
    background-repeat: no-repeat;
    button {
      background-color: $theme-color;
      border: none;
      i {
        color: #fff;
      }
      border-radius: 20px;
    }
  }
  h3 {
    color: $theme-color;
    width: 65%;
    margin-bottom: 3rem;
  }
}
